import React from "react";
import "./App.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faCheckSquare,
  faCoffee,
  faSpinner,
  faHome,
  faCode,
  faIdCard,
} from "@fortawesome/free-solid-svg-icons";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./utils/AuthContext";
import { RequestProvider } from "./utils/RequestContext";
import Layout from "./components/Layout";
import Home from "./screens/home";
import About from "./screens/about";
import Blogs from "./screens/blogs";
import PublishPost from "./screens/cms/postPublish";
import Test from "./screens/test";
import Default from "./screens/404.js";
import Labs from "./screens/Labs/index.js";

library.add(fab, faCheckSquare, faCoffee, faSpinner, faHome, faCode, faIdCard);
function App() {
  return (
    <div className="App">
      <AuthProvider>
        <RequestProvider>
          <BrowserRouter>
            <Layout from="home"></Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/blogs" element={<PublishPost />} />
              <Route path="/cms/publish/post" element={<Blogs />} />
              <Route path="/test" element={<Test />} />
              <Route path="/labs" element={<Labs />} />
              <Route path="*" element={<Default />} />
            </Routes>
          </BrowserRouter>
        </RequestProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
