import React from "react";

const Default = () => {
  return (
    <div>
      <span>404</span>
    </div>
  );
};

export default Default;
