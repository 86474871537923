import React from 'react';
import './ProfileHeader.scss'; // Import the corresponding CSS file for styles
import profilePic from '../../images/PSX_20230911_015502.jpg'; 
import LazyImage from '../LazyImage';// Import the image

const ProfileHeader = () => {
  return (
    <div className="profile-header">
      <div className="text-section">
        <h1>Khan Mohammed Tabish</h1>
        <p>
          Undefined Developer.
        </p>
      </div>
      <div className="image-section">
        <LazyImage src={profilePic} // Replace with the actual path to your image
          alt="Tabish" 
          className="profile-image"></LazyImage>
        {/* <img 
          src={profilePic} // Replace with the actual path to your image
          alt="Tabish" 
          className="profile-image"
        /> */}
        {/* Decorative shapes */}
        <div className="shape yellow-circle"></div>
        <div className="shape pink-gradient"></div>
        <div className="shape yellow-square"></div>
        <div className="shape yellow-circle1"></div>
        <div className="shape pink-gradient1"></div>
        <div className="shape yellow-square1"></div>
      </div>
    </div>
  );
};

export default ProfileHeader;
