import React from "react";
import Blogs from "../../components/Blogs";

const BlogsCmp = () => {
  return (
    <div>
      <Blogs></Blogs>
    </div>
  );
};

export default BlogsCmp;
