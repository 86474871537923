import { useReducer, useContext } from "react";
import { RequestContext } from "./RequestContext";

const ReducerConfigs = (state, action) => {
  switch (action.type) {
    case "REQUEST_INIT":
      return {
        ...state,
        data: null,
        error: null,
        isLoading: true,
        isFetched: true,
      };
    case "REQUEST_SUCCESS":
      return {
        ...state,
        data: action.data,
        error: null,
        isLoading: false,
        isFetched: true,
      };
    case "REQUEST_FAILURE":
      return {
        ...state,
        data: null,
        error: action.error,
        isLoading: false,
        isFetched: true,
      };
    default:
      throw new Error();
  }
};
const useRequestReducer = (requestEndPoint, method, payload) => {
  const requestContext = useContext(RequestContext);
  const [state, dispatch] = useReducer(ReducerConfigs, {
    data: null,
    error: null,
    isLoading: true,
    initData: payload || null,
    isFetched: false,
  });

  const makeRequest = async () => {
    dispatch({ type: "REQUEST_INIT" });
    try {
      const response = await requestContext.authAxios({
        method: method,
        url: requestEndPoint,
        data: state.initData,
      });
      dispatch({ type: "REQUEST_SUCCESS", data: response.data });
    } catch (error) {
      dispatch({ type: "REQUEST_ERROR", error: error });
    }
  };
  const onLoadRequest = async () => {
    if (state.isFetched) return;
    dispatch({ type: "REQUEST_INIT" });
    try {
      const response = await requestContext.authAxios({
        method: method,
        url: requestEndPoint,
        data: state.initData,
      });
      dispatch({ type: "REQUEST_SUCCESS", data: response.data });
    } catch (error) {
      dispatch({ type: "REQUEST_ERROR", error: error });
    }
  };
  return [
    state.data,
    state.error,
    state.isLoading,
    state.isFetched,
    makeRequest,
    onLoadRequest,
  ];
};

export { useRequestReducer };
