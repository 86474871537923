import React from "react";
import About from "../../components/About";

const AboutCmp = () => {
  return (
    <div>
      <About></About>
    </div>
  );
};

export default AboutCmp;
