import React from 'react';
import './App.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function App() {
  return (
    <div className="container">
      <div className="decorative-elements">
        {/* Decorative circles and shapes */}
        <div className="circle green"></div>
        <div className="circle yellow"></div>
        <div className="circle orange"></div>
        <div className="semi-circle grey"></div>
      </div>
      <div className="login-form">
        <div className="logo">
          {/* <img src="/path-to-logo.png" alt="Logo" /> */}
           <FontAwesomeIcon icon="home" fixedWidth />
        </div>
        <h2>Welcome Back!</h2>
        <p>Not a user? <a href="/register">Register Now</a></p>
        <form>
          <div className="input-group">
            <label>Email</label>
            <input type="email" placeholder="Enter your email" />
          </div>
          <div className="input-group">
            <label>Password</label>
            <input type="password" placeholder="Enter your password" />
          </div>
          <button type="submit">Login</button>
          <a href="/forgot-password" className="forgot-password">Forgot Password?</a>
        </form>
      </div>
    </div>
  );
}

export default App;
