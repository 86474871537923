import React, { useState }from "react";
// import Code from "../Code";
import "./style.scss";

export default function Home() {
  const [isDripping, setIsDripping] = useState(false); // State to control dripping animation

  console.log("INSIDE HOME");
  const word = 'semicolon'; // The word to display
  const letters = word.split(''); // Split the word into individual characters
  const handleDripClick = () => {
    setIsDripping(true); // Trigger the dripping effect
  };
  return (
      <div className="content">
      {isDripping &&  <div className="background-image-text">
          <span className="letter-circle-semicolon">;</span> I am very Important
        </div>}

        <div className="semicolon-container">
          {letters.map((letter, index) => (
               <div
               key={index}
               className={`letter-circle ${isDripping ? 'drip' : ''}`} // Add 'drip' class when dripping is triggered
             >
              {letter.toUpperCase()}
            </div>
          ))}
          <div
            className={`${!isDripping ? 'delete-icon letter-circle-semicolon' : 'letter-circle-semicolon hide'}`}
            title="Click here to remove me"
            onClick={() => handleDripClick()}
          >
            ;
            <span className="tooltip">Click me to remove</span>

          </div>  

        </div>
       
      </div>

  );
}
