import React from "react";
import "./style.scss";
const Layout = () => {
  return (
    <div>
      <div className="footer">
        <div className="footer-title">Version: 1.0.0</div>
        <div className="footer-title">All rights reserved to semicolon.xyz</div>
      </div>
    </div>
  );
};

export default Layout;
