import React from "react";
import Post from "../Post";

export default function Blogs() {
  return (
    <div>
      <div className="content">
        My Blogs... <br></br><br></br>
        {["1st", "2nd", "3rd", "4th", "5th"].map((item, index) => {
          return <Post postId={item} key={`key-${index}`}></Post>;
        })}
        <Post></Post>
      </div>
    </div>
  );
}
