import React, { useEffect, useState } from "react";
import { useRequestReducer } from "../../utils/RequestReducer";

const AddPost = (props) => {
  console.log("INSIDE PUBLISH");
  const [isCalled, setIsCalled] = useState(false);
  const [data, error, isLoading, isFetched, makeRequest, onLoadRequest] =
    useRequestReducer("/post", "GET", {});
  useEffect(() => {
    if (!isCalled) {
      onLoadRequest();
      console.log("TEST", isCalled);
      setIsCalled(true);
    }
  }, [
    isLoading,
    data,
    error,
    isFetched,
    isCalled,
    setIsCalled,
    makeRequest,
    onLoadRequest,
  ]);

  return (
    <>
      <div>Hello This is from CMS</div>
      <button onClick={() => makeRequest()}>TEST</button>
      {data?.data?.map((post, index) => (
        <div key={`key-${index}`}>{post.title}</div>
      ))}
    </>
  );
};

export default AddPost;
