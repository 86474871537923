import React, { useState, useEffect, useCallback} from 'react';

const AdjustableSmileyFace = () => {
  const [smileLevel, setSmileLevel] = useState(0); // smileLevel ranges from -50 (frown) to 50 (big smile)

  // Function to generate the smile path based on smile level
  const getSmilePath = useCallback(() => {
    // Control point for the quadratic curve (adjusts the smile)
    const controlPointY = 300 - smileLevel; // Adjusted based on provided SVG dimensions

    // Create the path string dynamically
    return `M 210 400 Q 300 ${controlPointY} 390 400`; // Adjusted coordinates for the smile
  }, [smileLevel]);

  useEffect(() => {
    // Get the path element and update its 'd' attribute dynamically
    const smilePath = document.getElementById('smile-path');
    if (smilePath) {
      smilePath.setAttribute('d', getSmilePath());
    }
  }, [getSmilePath, smileLevel]);

  return (
<div className="smiley-face-container">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="500vw"
    height="500vw"
    viewBox="0 0 768 768"
    preserveAspectRatio="xMidYMid meet"
    style={{ display: 'block', maxWidth: '100%', height: 'auto' }}
  >
    <g clipPath="url(#0532be35e6)">
      <g clipPath="url(#6860e65fcb)"></g>
    </g>
    <path
      id="smile-path"
      d="M 210 400 Q 300 300 390 400"
      stroke="black"
      strokeWidth="4"
      fill="transparent"
    ></path>
  </svg>
  <button onClick={() => setSmileLevel(smileLevel - 10)}>Test</button>
</div>

  );
};

export default AdjustableSmileyFace;


