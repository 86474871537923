import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { AuthContext } from "./AuthContext";

const RequestContext = createContext();
const { Provider } = RequestContext;
const RequestProvider = ({ children }) => {
  const authContext = useContext(AuthContext);
  const authAxios = axios.create({
    // baseURL: process.env.REACT_APP_API_URL || "http://localhost:4000", // For Local Development
    baseURL: process.env.REACT_APP_API_URL || "https://semicolon-server.netlify.app/",

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  });

  authAxios.interceptors.request.use(
    (config) => {
      const configWithHeaders = { ...config };
      if (authContext.authToken) {
        configWithHeaders.headers.Authorization = `Bearer ${authContext.authToken}`;
      }
      return configWithHeaders;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  authAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // const code = error && error.response ? error.response.status : 0;
      // if (code === 401) {
      //   authContext.logout();
      // }
      // if (code === 403) {
      //   authContext.setAccessDenied(true);
      // }
      return Promise.reject(error);
    }
  );

  return (
    <Provider
      value={{
        authAxios,
      }}
    >
      {children}
    </Provider>
  );
};

RequestContext.propTypes = {
  children: PropTypes.node.isRequired,
};

export { RequestContext, RequestProvider };
