import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const token = localStorage.getItem("token");

  const [authToken, updateAuthToken] = useState(token);
  const updateToken = (newToken) => updateAuthToken(newToken);

  return <Provider value={{ authToken, updateToken }}>{children}</Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthProvider, AuthContext };
