import React, { useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SmileyFace from "./SmileyFace";
import "./style.scss";

export default function Lab() {
  const videoRef = useRef(null); // Reference to the video element
  const streamRef = useRef(null); // Reference to store the media stream

  const handleStopCamera = () => {
    console.log("Stopping camera", videoRef.current, videoRef);

    if (streamRef.current) {
      const tracks = streamRef.current.getTracks(); // Get all tracks from the stream
      tracks.forEach((track) => {
        track.stop(); // Stop each track
      });
      streamRef.current = null; // Clear the stream reference

      if (videoRef.current) {
        videoRef.current.srcObject = null; // Clear the video source if still available
      }
    }
  };

  useEffect(() => {
    const constraints = { video: true }; // Video constraints for getUserMedia

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        streamRef.current = stream; // Store the stream in the ref
        if (videoRef.current) {
          videoRef.current.srcObject = stream; // Assign the stream to the video element
        }
      })
      .catch((error) => {
        console.error("Error accessing camera:", error);
      });

    // Cleanup function to stop the camera when the component unmounts
    return () => {
      handleStopCamera();
    };
  }, []);

  return (
    <>
      <div className="stylish-title">LAB</div>
      <div className="container">
        <div className="video-button-container camera">
          <div className="circle-video">
            <video ref={videoRef} autoPlay playsInline className="circle-video" />
          </div>
          <button className="flat-button">
            <FontAwesomeIcon icon="spinner" spin />
            <span style={{ paddingLeft: "5px" }}>Process the Video</span>
          </button>
        </div>
        <SmileyFace />
      </div>
    </>
  );
}
