import React from "react";
import Home from "../../components/Home";
import "../style.scss";
import "prismjs/themes/prism-solarizedlight.css";

import "prismjs";
export default function Main() {
  return (
    <div className="content">
      <Home path="/" />
    </div>
  );
}
