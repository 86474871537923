import React from "react";
import Lab from "../../components/Lab";

export default function Labs() {
  return (
    <div className="content">
      <Lab />
    </div>
  );
}
